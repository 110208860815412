export const permissionList = [
  {
    markdown: `{% inlineRouterLink %}Change member roles{% /inlineRouterLink %}`,
    ownerAllowed: true,
    billingAllowed: false,
    memberAllowed: false,
  },
  {
    markdown: `{% inlineRouterLink %}Delete a team{% /inlineRouterLink %}`,
    ownerAllowed: true,
    billingAllowed: false,
    memberAllowed: false,
  },
  {
    markdown: `{% inlineRouterLink %}Edit team name{% /inlineRouterLink %}`,
    ownerAllowed: true,
    billingAllowed: false,
    memberAllowed: false,
  },
  {
    markdown: `{% inlineRouterLink %}Remove a team member{% /inlineRouterLink %}`,
    ownerAllowed: true,
    billingAllowed: false,
    memberAllowed: false,
  },
  {
    markdown: `{% inlineRouterLink %}Connect payment method{% /inlineRouterLink %}`,
    ownerAllowed: true,
    billingAllowed: true,
    memberAllowed: false,
  },
  {
    markdown: `{% inlineRouterLink %}Connect payout account{% /inlineRouterLink %}`,
    ownerAllowed: true,
    billingAllowed: true,
    memberAllowed: false,
  },
  {
    markdown: `{% inlineRouterLink %}Pay for member usage{% /inlineRouterLink %}`,
    ownerAllowed: true,
    billingAllowed: true,
    memberAllowed: false,
  },
  {
    markdown: `{% inlineRouterLink %}Leave a team{% /inlineRouterLink %}`,
    ownerAllowed: false,
    billingAllowed: true,
    memberAllowed: true,
  },
  {
    markdown: `{% inlineRouterLink %}Enable safezone sharing rules{% /inlineRouterLink %}`,
    ownerAllowed: true,
    billingAllowed: true,
    memberAllowed: true,
  },
];

export const permissionsByRoleInTowerHunt = {
  order: 1,
  name: "Permissions by role in Tower Hunt",
  subcategory: "Review roles & permissions",
  markdown: `# Permissions by role in Tower Hunt
  In Tower Hunt, every team member has a role, each with its own level of permissions and access. Use the tables below to explore specific permissions for each {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}type of role{% /inlineRouterLink %}.

  ## Manage Tower Hunt teams

  Breakdown of permissions by role type for managing teams.

  {% permissionsTable permissions=$permissionList marginStyles="" /%}`,
};
